import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";
import Home from "./views/home";
import NotFound from "./views/not-found";
import AgencyOnboarding from "./components/agencyOnboarding";

const App = () => {
  const appStyle = {
    backgroundColor: "#000",
    color: "#fff",
    minHeight: "100vh",
    margin: 0,
    padding: 0,
    fontFamily: "Arial, sans-serif",
  };
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Home} exact path="/businessrequirement/" />
        <div style={appStyle}>
        <Route
          component={AgencyOnboarding}
          exact
          path="/agencyonboarding/"
        />
        </div>
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
