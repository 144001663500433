import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push } from "firebase/database";

// Firebase configuration (ensure these are set in your .env file)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const AgencyOnboarding = () => {
  const [formData, setFormData] = useState({
    agencyName: "",
    agencyDomains:"",
    agencyAbout: "",
    agencyPhone: "",
    agencyServices: "",
    agencyWebsite: "",
    contactPersonName: "",
    contactPersonPhone: "",
    agencySocials: "",
    employeesPermanent: "",
    employeesTemporary: "",
    project1File: "",
    project1RefContact: "",
    project1RefLinkedin: "",
    project2File: "",
    project2RefContact: "",
    project2RefLinkedin: "",
    project3File: "",
    project3RefContact: "",
    project3RefLinkedin: "",
    agencyLocation: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      // For simplicity, just store the file name. In reality, you'd upload the file and store a URL.
      setFormData((prev) => ({
        ...prev,
        [name]: files[0] ? files[0].name : "",
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const database = getDatabase(app);
    const requirementsRef = ref(database, "agency_onboardings");
    push(requirementsRef, formData)
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("Error saving data: ", error);
      });
  };

  // Theme-related inline styles
  const containerStyle = {
    background: "#000",
    color: "#fff",
    padding: "40px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "600px",
    margin: "0 auto",
    borderRadius: "8px",
  };

  const titleStyle = {
    fontSize: "28px",
    marginBottom: "10px",
    fontWeight: "bold",
  };

  const subtitleStyle = {
    fontSize: "16px",
    marginBottom: "30px",
    color: "#ccc",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: "bold",
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    marginBottom: "20px",
    background: "#111",
    border: "1px solid #333",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "14px",
  };

  const textareaStyle = {
    ...inputStyle,
    height: "80px",
    resize: "vertical",
  };

  const buttonStyle = {
    background: "#00FF7F",
    border: "none",
    color: "#000",
    padding: "12px 20px",
    borderRadius: "4px",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "16px",
    width: "100%",
  };

  const thankYouStyle = {
    textAlign: "center",
  };

  const main_agency_on = {
    background: "#000",
    color: "#fff",
    padding: "40px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "100%",
    margin: "0 auto",
  };

  return (
    <div style={main_agency_on}>
      <div style={containerStyle}>
        {submitted ? (
          <div style={thankYouStyle}>
            <h3 style={{ fontSize: "24px", marginBottom: "10px" }}>
              Thank you for submitting your details!
            </h3>
            <p style={{ fontSize: "16px", color: "#ccc" }}>
              We will get back soon after verifying the data you've provided.
            </p>
          </div>
        ) : (
          <>
            <h2 style={titleStyle}>Ready to get onboarded ?</h2>
            <p style={subtitleStyle}>
              Fill out the form below to join our platform and connect with top
              clients.
            </p>
            <form onSubmit={handleSubmit}>
              <label htmlFor="agencyName" style={labelStyle}>
                Name of Agency:
              </label>
              <input
                type="text"
                id="agencyName"
                name="agencyName"
                value={formData.agencyName}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Enter your agency's name"
              />

              <label htmlFor="agencyPhone" style={labelStyle}>
                Phone Number of Agency:
              </label>
              <input
                type="text"
                id="agencyPhone"
                name="agencyPhone"
                value={formData.agencyPhone}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Enter agency's phone number"
              />

              <label htmlFor="agencyAbout" style={labelStyle}>
                About Your Agency
              </label>
              <input
                type="text"
                id="agencyAbout"
                name="agencyAbout"
                value={formData.agencyAbout}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Upto 3 lines"
              />

              <label htmlFor="agencyWebsite" style={labelStyle}>
                Website of Agency:
              </label>
              <input
                type="text"
                id="agencyWebsite"
                name="agencyWebsite"
                value={formData.agencyWebsite}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="e.g. https://youragency.com"
              />

              <label htmlFor="agencyServices" style={labelStyle}>
                Services Your Agencies Provide:
              </label>
              <input
                type="text"
                id="agencyServices"
                name="agencyServices"
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Upto 3 Services"
              />

            <label htmlFor="agencyDomains" style={labelStyle}>
                Industry Expertise (Upto 4) :
              </label>
              <input
                type="text"
                id="agencyDomains"
                name="agencyDomains"
                value={formData.agencyDomains}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Biotech, Fintech, HealthTech"
              />

              <label htmlFor="contactPersonName" style={labelStyle}>
                Name of Contact Person:
              </label>
              <input
                type="text"
                id="contactPersonName"
                name="contactPersonName"
                value={formData.contactPersonName}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Full name of contact person"
              />

              <label htmlFor="contactPersonPhone" style={labelStyle}>
                Phone Number of Contact Person:
              </label>
              <input
                type="text"
                id="contactPersonPhone"
                name="contactPersonPhone"
                value={formData.contactPersonPhone}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Contact person's phone number"
              />

              <label htmlFor="agencySocials" style={labelStyle}>
                Linkedin, Instagram, and Other Social Media of your Agency:
              </label>
              <textarea
                id="agencySocials"
                name="agencySocials"
                value={formData.agencySocials}
                onChange={handleChange}
                required
                style={textareaStyle}
                placeholder="Provide links to your LinkedIn, Instagram, etc."
              />

              <label style={labelStyle}>Number of Employees:</label>
              <div style={{ display: "flex", gap: "10px" }}>
                <input
                  type="text"
                  name="employeesPermanent"
                  value={formData.employeesPermanent}
                  onChange={handleChange}
                  required
                  style={{ ...inputStyle, marginBottom: 0 }}
                  placeholder="Permanent (As per LinkedIn)"
                />
                <input
                  type="text"
                  name="employeesTemporary"
                  value={formData.employeesTemporary}
                  onChange={handleChange}
                  required
                  style={{ ...inputStyle, marginBottom: 0 }}
                  placeholder="Temporary"
                />
              </div>
              <div style={{ marginBottom: "20px" }}></div>
              <label htmlFor="agencyLocation" style={labelStyle}>
                Geographic Location:
              </label>
              <input
                type="text"
                id="agencyLocation"
                name="agencyLocation"
                value={formData.agencyLocation}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="e.g. Delhi, Bengaluru"
              />
              <h3 style={{ fontSize: "20px", margin: "20px 0 10px" }}>
                Upload 3 Most Recent Projects
              </h3>
              <p style={{ color: "#ccc", marginBottom: "20px" }}>
                For each project provide:
                <br />
                1. Link to google drive with reference files uploaded.
                <br />
                2. Reference contact (phone/email). <br />
                3. LinkedIn profile of the reference contact person.
              </p>

              {/* Project 1 */}
              <label htmlFor="project1File" style={labelStyle}>
                Project 1 Drive Link:
              </label>
              <input
                type="text"
                id="project1File"
                name="project1File"
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Link to Drive"
              />

              <label htmlFor="project1RefContact" style={labelStyle}>
                Project 1 Reference Contact:
              </label>
              <input
                type="text"
                id="project1RefContact"
                name="project1RefContact"
                value={formData.project1RefContact}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="Phone or Email"
              />

              <label htmlFor="project1RefLinkedin" style={labelStyle}>
                Project 1 Reference LinkedIn:
              </label>
              <input
                type="text"
                id="project1RefLinkedin"
                name="project1RefLinkedin"
                value={formData.project1RefLinkedin}
                onChange={handleChange}
                required
                style={inputStyle}
                placeholder="LinkedIn URL"
              />

              {/* Project 2 */}
              <label htmlFor="project2File" style={labelStyle}>
                Project 2 Drive Link:
              </label>
              <input
                type="text"
                id="project2File"
                name="project2File"
                onChange={handleChange}
                style={inputStyle}
                placeholder="Link to Drive"
              />

              <label htmlFor="project2RefContact" style={labelStyle}>
                Project 2 Reference Contact:
              </label>
              <input
                type="text"
                id="project2RefContact"
                name="project2RefContact"
                value={formData.project2RefContact}
                onChange={handleChange}
                style={inputStyle}
                placeholder="Phone or Email"
              />

              <label htmlFor="project2RefLinkedin" style={labelStyle}>
                Project 2 Reference LinkedIn:
              </label>
              <input
                type="text"
                id="project2RefLinkedin"
                name="project2RefLinkedin"
                value={formData.project2RefLinkedin}
                onChange={handleChange}
                style={inputStyle}
                placeholder="LinkedIn URL"
              />

              {/* Project 3 */}
              <label htmlFor="project3File" style={labelStyle}>
                Project 3 Drive Link:
              </label>
              <input
                type="text"
                id="project3File"
                name="project3File"
                onChange={handleChange}
                style={inputStyle}
                placeholder="Link to Drive"
              />

              <label htmlFor="project3RefContact" style={labelStyle}>
                Project 3 Reference Contact:
              </label>
              <input
                type="text"
                id="project3RefContact"
                name="project3RefContact"
                value={formData.project3RefContact}
                onChange={handleChange}
                style={inputStyle}
                placeholder="Phone or Email"
              />

              <label htmlFor="project3RefLinkedin" style={labelStyle}>
                Project 3 Reference LinkedIn:
              </label>
              <input
                type="text"
                id="project3RefLinkedin"
                name="project3RefLinkedin"
                value={formData.project3RefLinkedin}
                onChange={handleChange}
                style={inputStyle}
                placeholder="LinkedIn URL"
              />

              <button type="submit" style={buttonStyle}>
                Submit Agency Details
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default AgencyOnboarding;
