import React from "react";
import { useState } from "react";
import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";
import Modal from "../components/modal";
import Form from "../components/waitlist";

import Navbar from "../components/navbar";
import Mark from "../components/mark";
import Card from "../components/card";
import Accordion from "../components/accordion";
import Includes from "../components/includes";
import Excludes from "../components/excludes";
import Review from "../components/review";
import Article from "../components/article";
import FAQ from "../components/faq";
import "./home.css";

const Home = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home-container1">
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <Form onSuccess={closeModal} />
      </Modal>
      <Helmet>
        <title>AgencyMart</title>
        <meta property="og:title" content="AgencyMart" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name"></Navbar>
      <section className="home-section10">
        <div className="home-hero">
          <div className="home-content1">
            <main className="home-main1">
              <header className="home-header10">
                <h1 className="home-heading10">
                  Discover Trusted Agencies Faster
                </h1>
                <span className="home-caption1">
                  We connect You with top-tier agencies tailored to your
                  business needs.
                </span>
              </header>
              <div className="home-buttons1">
                <div
                  className="home-get-started1 button open-modal-button"
                  onClick={openModal}
                >
                  <button
                    className="home-text10"
                    // onClick={openModal}
                  >
                    Join the Waitlist
                  </button>
                </div>
                <a href="mailto:contact@agencymart.info">
                  <div className="home-get-started2 button">
                    <a className="home-text11">Talk to Founders</a>
                  </div>
                </a>
              </div>
            </main>
            <div className="home-highlight">
              <div className="home-avatars">
                <img
                  alt="image"
                  src="/Icons/zuruai.png"
                  className="home-image10 avatar"
                />
                <img
                  alt="image"
                  src="/Icons/ironasylum.png"
                  className="home-image11 avatar"
                />
                <img
                  alt="image"
                  src="/Icons/setu.png"
                  className="home-image12 avatar"
                />
              </div>
              <label className="home-caption2">
                AgencyMart is Loved by Businesses and Top Agencies alike.
              </label>
            </div>
          </div>
          <div className="home-image13">
            <img
              alt="image"
              src="/SectionImages/Framehero.png"
              className="home-image14"
            />
          </div>
          <div className="home-image15">
            <img
              alt="image"
              src="/SectionImages/Framehero.png"
              className="home-image16"
            />
          </div>
        </div>
      </section>
      <section className="home-section11">
        <h2 className="home-text12">
          Our Team is Here to Support You, Anytime.
        </h2>
        <div className="home-features1">
          <header className="feature feature-active home-feature1">
            <h3 className="home-text13">Access Top Agencies with Ease</h3>
            {/* <p className="home-text14">Doloremque laudantium</p> */}
          </header>
          {/* <header className="feature home-feature2">
            <h3 className="home-text15">Chronic Care</h3>
            <p className="home-text16">Doloremque laudantium</p>
          </header>
          <header className="feature home-feature3">
            <h3 className="home-text17">Mental Health</h3>
            <p className="home-text18">Doloremque laudantium</p>
          </header> */}
        </div>
        <div className="home-note1">
          <div className="home-content2">
            <main className="home-main2">
              {/* <h2 className="home-heading11">
                Accessing this Medicare benefit is easy
              </h2> */}
              <p className="home-paragraph1">
                <span>
                  At AgencyMart, we simplify the process of finding and hiring
                  the best agencies for your business needs.
                </span>
                <br></br>
                <br></br>
                <p>Our platform offers:</p>
                <p>
                  • Vetted and Trusted Agencies: Connect with agencies that have
                  a proven track record.
                </p>
                <p>
                  • Expert Assistance in Drafting Requirements: Craft precise
                  project briefs with our help.
                </p>
                <p>
                  • Field Experts to Evaluate Proposals: Get professional
                  insights to choose the best pitch.
                </p>
                <br></br>
              </p>
            </main>
            <div className="home-explore-more">
              <a
                className="home-text24"
                href="https://www.linkedin.com/company/agency-mart/"
              >
                Explore more -&gt;
              </a>
            </div>
          </div>
          {/* <div className="home-image17">
            <img
              alt="image"
              src="/SectionImages/group%201490-1200w.png"
              className="home-image18"
            />
          </div> */}
        </div>
      </section>
      <section className="home-section12">
        {/* <header className="home-header11">
          <h2 className="home-text25">Why do you need a mobile medical app?</h2>
          <span className="home-text26">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </span>
        </header> */}
        {/* <section className="home-note2">
          <div className="home-image19">
            <img
              alt="image"
              src="/SectionImages/group%201428-1200w.png"
              className="home-image20"
            />
          </div>
          <div className="home-content3">
            <div className="home-main3">
              <div className="home-caption3">
                <span className="section-head">Tempor incididunt</span>
              </div>
              <div className="home-heading12">
                <h2 className="section-heading">
                  We provide compassionate care from start to finish.
                </h2>
                <p className="section-description">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae.
                </p>
              </div>
            </div>
            <div className="home-get-started3 button">
              <span className="home-text27">Get started</span>
            </div>
          </div>
        </section> */}
        {/* <section className="home-note3">
          <div className="home-image21">
            <img
              alt="image"
              src="/SectionImages/group%201449-1200w.png"
              className="home-image22"
            />
          </div>
          <div className="home-content4">
            <main className="home-main4">
              <header className="home-caption4">
                <span className="home-section14 section-head">
                  Tempor incididunt
                </span>
              </header>
              <main className="home-heading14">
                <header className="home-header12">
                  <h2 className="section-heading">
                    Great care, wherever you are
                  </h2>
                  <p className="section-description">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium.
                  </p>
                </header>
                <div className="home-checkmarks">
                  <Mark></Mark>
                  <Mark label="Quis nostrud exercitation ullamco"></Mark>
                  <Mark label="Reprehenderit qui in ea voluptate velit"></Mark>
                </div>
              </main>
            </main>
            <div className="home-get-started4 button">
              <span className="home-text28">Get started</span>
            </div>
          </div>
        </section> */}
      </section>
      <section className="home-section15">
        <header className="home-header13">
          <header className="home-left1">
            <span className="section-head">How it Works ?</span>
            <h2 className="section-heading">
              <span>Top Agency Options</span>
              <br></br>
              <span>Just Few Steps Away</span>
            </h2>
          </header>
          <div className="home-right1">
            <p className="home-paragraph4 section-description">
              Your Trust is our unwavering promise. <br /> Rely on us to connect
              you with top-tier agencies you can confidently partner with.
            </p>
          </div>
        </header>
        <main className="home-cards1">
          <Card
            rootClassName="cardroot-class-name"
            header="Posting Requirement"
            description="Our Experts will help you draft your requirements in the best way possible."
          ></Card>
          <Card
            icon="/Icons/group%201314-200h.png"
            rootClassName="cardroot-class-name1"
            header="Agency Pitching"
            description="Agencies Pitch to show interest and provide their most relevant work to impress you"
          ></Card>
          <Card
            icon="/Icons/group%201317-200h.png"
            rootClassName="cardroot-class-name2"
            header="Connecting the dots"
            description="Our team will help you connect the agencies you shortlist for your work"
          ></Card>
        </main>
      </section>
      <section className="home-section17">
        {/* <div className="home-note4">
          <div className="home-image23">
            <img
              alt="image"
              src="/SectionImages/iphone%2014%20pro%20max-1200w.png"
              className="home-image24"
            />
          </div>
          <div className="home-content5">
            <div className="home-caption5">
              <span className="section-head">Tempor incididunt</span>
            </div>
            <div className="home-heading17">
              <div className="home-header14">
                <h2 className="section-heading">
                  Tips to get care, answers and advice faster
                </h2>
              </div>
              <Accordion rootClassName="accordionroot-class-name"></Accordion>
            </div>
          </div>
        </div> */}
      </section>
      <section className="home-section19">
        <div className="home-cube1">
          <div className="home-top1 side"></div>
          <div className="home-front1 side"></div>
          <div className="home-left2 side"></div>
        </div>
        <main className="home-banner">
          <div className="home-header15">
            <h2 className="section-heading">
              AgencyMart makes outsourcing trustworthy
            </h2>
            <p className="home-description1 section-description">
              Your Trust is our Top Priority
            </p>
          </div>
          <div className="home-buttons2">
            <div
              className="home-get-started5 button button open-modal-button"
              onClick={openModal}
            >
              <span className="home-text32">Join the Waitlist</span>
            </div>
            <a href="mailto:contact@agencymart.info">
              <div className="home-book-demo button">
                <a className="home-text33">Talk to Founders</a>
              </div>
            </a>
          </div>
        </main>
      </section>
      <section className="home-section20">
        <div className="home-cube2">
          <div className="home-top2 side"></div>
          <div className="home-front2 side"></div>
          <div className="home-left3 side"></div>
        </div>
        <main className="home-pricing1">
          <header className="home-header16">
            <header className="home-left4">
              <span className="section-head">Pricing</span>
              <h2 className="section-heading home-heading20">Free Forever</h2>
            </header>
            <div className="home-right2">
              <p className="home-paragraph5 section-description">
                100% free for Businesses with NO hidden charges
              </p>
            </div>
          </header>
          {/* <div className="home-plans-container">
            <div className="home-switch1">
              <div className="switch">
                <label className="home-text34">Monthly</label>
              </div>
              <div className="home-switch3 switch">
                <label className="home-text35">Yearly</label>
              </div>
            </div>
            <main className="home-plans">
              <div className="home-plan1">
                <div className="home-details1">
                  <div className="home-header17">
                    <label className="home-name1">Basic</label>
                    <div className="home-pricing2">
                      <h1 className="home-price1">$9</h1>
                      <span className="home-duration1">/mo</span>
                    </div>
                  </div>
                  <p className="home-description2">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="home-buy-details1">
                  <div className="home-buy1 button">
                    <span className="home-text36">
                      <span>Start Basic</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="home-features2">
                    <span className="home-heading21">You will get</span>
                    <div className="home-list1">
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan2">
                <div className="home-details2">
                  <div className="home-header18">
                    <label className="home-name2">Professional</label>
                    <div className="home-pricing3">
                      <h1 className="home-price2">$15</h1>
                      <span className="home-duration2">/mo</span>
                    </div>
                  </div>
                  <p className="home-description3">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="home-buy-details2">
                  <div className="home-buy2 button">
                    <span className="home-text39">
                      <span>Start Professional</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="home-features3">
                    <span className="home-heading22">You will get</span>
                    <div className="home-list2">
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                      <Excludes rootClassName="excludesroot-class-name"></Excludes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan3">
                <div className="home-details3">
                  <div className="home-header19">
                    <label className="home-name3">Enterprise</label>
                    <div className="home-pricing4">
                      <span className="home-price3">$99</span>
                      <span className="home-duration3">/mo</span>
                    </div>
                  </div>
                  <p className="home-description4">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="home-buy-details3">
                  <div className="home-buy3 button">
                    <span className="home-text42">
                      <span>Start Enterprise</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="home-features4">
                    <span className="home-heading23">You will get</span>
                    <div className="home-list3">
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                      <Includes rootClassName="includesroot-class-name"></Includes>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div> */}
        </main>
        <div className="home-help">
          <span className="home-text45">
            <span>Need any help?</span>
            <br></br>
          </span>
          <div className="home-contact-support">
            <a
              className="home-text48"
              href="mailto:contact@agencymart.info"
            >
              Contact support -&gt;
            </a>
          </div>
        </div>
      </section>
      <section className="home-section22">
        {/* <header className="home-header20">
          <header className="home-left5">
            <span className="section-head">Tempor incididunt</span>
            <h2 className="home-heading24 section-heading">
              What users say about us
            </h2>
          </header>
          <div className="home-right3">
            <p className="home-paragraph6 section-description">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam.
            </p>
          </div>
        </header>
        <main className="home-cards2">
          <div className="home-container2">
            <Review rootClassName="reviewroot-class-name"></Review>
            <Review
              quote="“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.\u2028\u2028Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="home-container3">
            <Review
              quote="“Illum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
          <div className="home-container4">
            <Review
              quote="“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
            <Review
              quote="“Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="reviewroot-class-name"
            ></Review>
          </div>
        </main>
        <div className="home-view-more">
          <p className="home-text49">View more</p>
        </div> */}
      </section>
      <section className="home-section24">
        {/* <header className="home-header21">
          <span className="section-head">Articles about us</span>
          <h2 className="home-heading25 section-heading">
            We’re the app on everyone’s lips
          </h2>
        </header>
        <main className="home-cards3">
          <Article rootClassName="articleroot-class-name"></Article>
          <Article
            header="techeu"
            specialHeader="eu"
            rootClassName="articleroot-class-name"
          ></Article>
          <Article
            header="sifted/"
            rootClassName="articleroot-class-name"
          ></Article>
        </main> */}
      </section>
      <section className="home-section26">
        <header className="home-header22">
          <span className="section-head">FAQ</span>
          <h2 className="home-heading26 section-heading">
            Frequently asked questions
          </h2>
        </header>
        <main className="home-accordion">
          <FAQ rootClassName="fa-qroot-class-name"></FAQ>
        </main>
      </section>
      <section className="home-section28">
        {/* <main className="home-content6">
          <header className="home-header23">
            <h2 className="home-heading27 section-heading">
              Stop searching online for medications and use AgencyMart app!
            </h2>
            <div className="home-buttons3">
              <div className="home-ios button">
                <img
                  alt="image"
                  src="/Icons/apple-200w.png"
                  className="home-icon1"
                />
                <span className="home-text50">Download for iOS</span>
              </div>
              <div className="home-android button">
                <img
                  alt="image"
                  src="/Icons/android-200h.png"
                  className="home-icon2"
                />
                <span className="home-text51">Download for Android</span>
              </div>
            </div>
          </header>
          <img
            alt="image"
            src="/SectionImages/group%201505-1200w.png"
            className="home-image25"
          />
        </main> */}
      </section>
      <footer className="home-footer">
        <div className="home-content7">
          <main className="home-main-content">
            <div className="home-content8">
              <header className="home-main5">
                <div className="home-header24">
                  <img
                    alt="image"
                    src="/Branding/darklogo.png"
                    className="home-branding"
                  />
                  <span className="home-text52">
                    Connect with us here on our Socials
                  </span>
                </div>
                <div className="home-socials">
                  <a
                    href="https://www.linkedin.com/company/agency-mart/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link1"
                  >
                    <img
                      alt="image"
                      src="/Icons/linkedin-200h.png"
                      className="social"
                    />
                  </a>
                  {/* <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    <img
                      alt="image"
                      src="/Icons/instagram-200h.png"
                      className="social"
                    />
                  </a> */}
                  <a
                    href="https://twitter.com/CareerWtPrakhar"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link3"
                  >
                    <img
                      alt="image"
                      src="/Icons/twitter-200h.png"
                      className="social"
                    />
                  </a>
                </div>
              </header>
              <header className="home-categories">
                {/* <div className="home-category1">
                  <div className="home-header25">
                    <span className="footer-header">Solutions</span>
                  </div>
                  <div className="home-links1">
                    <span className="footer-link">Responsive Web Design</span>
                    <span className="footer-link">Responsive Prototypes</span>
                    <span className="footer-link">Design to Code</span>
                    <span className="footer-link">Static Website Builder</span>
                    <span className="footer-link">
                      Static Website Generator
                    </span>
                  </div>
                </div> */}
                <div className="home-category2">
                  <div className="home-header26">
                    <span className="footer-header">Contact</span>
                  </div>
                  <div className="home-links2">
                    <span className="footer-link">
                      contact@agencymart.info
                    </span>
                    <span className="footer-link">Phone: +91 7003405610</span>
                    {/* <span className="footer-link">News</span>
                    <span className="footer-link">Partners</span>
                    <span className="footer-link">Careers</span>
                    <span className="footer-link">Press &amp; Media</span> */}
                  </div>
                </div>
              </header>
            </div>
            <section className="home-copyright1">
              <span className="home-text66">© 2024. All Rights Reserved.</span>
            </section>
          </main>
          <main className="home-subscribe">
            <main
              className="home-main6 button open-modal-button"
              onClick={openModal}
            >
              <h1
                className="home-heading28"
                // onClick={openModal}
              >
                Join the Waitlist
              </h1>
              <div className="home-input-field">
                {/* <input
                  type="email"
                  placeholder="Enter your email"
                  className="home-textinput input"
                /> */}
                <div className="home-buy4 button">
                  <span className="home-text67">-&gt;</span>
                  <span className="home-text68">
                    <button className="open-modal-button" onClick={openModal}>
                      Join now
                    </button>
                    <br></br>
                  </span>
                </div>
              </div>
            </main>
            <h1 className="home-notice open-modal-button" onClick={openModal}>
              Be part of our journey by joining the Waitlist.
            </h1>
          </main>
          <section className="home-copyright2">
            <span className="home-text71">© 2024. All Rights Reserved.</span>
          </section>
        </div>
      </footer>
      <div>
        <div className="home-container6">
          <Script
            html={`<script>
    /*
Accordion - Code Embed
*/

/* listenForUrlChangesAccordion() makes sure that if you changes pages inside your app,
the Accordions will still work*/

const listenForUrlChangesAccordion = () => {
      let url = location.href;
      document.body.addEventListener(
        "click",
        () => {
          requestAnimationFrame(() => {
            if (url !== location.href) {
              runAccordionCodeEmbed();
              url = location.href;
            }
          });
        },
        true
      );
    };


const runAccordionCodeEmbed = () => {
    const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
    const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]'); // All accordion icons

    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = "none"; //Hides all accordion contents
    });

    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener("click", () => {
            accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
            });

            accordionIcons.forEach((accordionIcon) => {
                accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
            });

            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        });
    });
}

runAccordionCodeEmbed()
listenForUrlChangesAccordion()

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
          ></Script>
        </div>
      </div>
    </div>
  );
};

export default Home;
